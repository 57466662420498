import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  image: {
    maxWidth: "90%",
    borderRadius: "20px",
    objectFit: "cover",
    boxShadow: "0.5em 0.5em 1em",
    margin: "2rem 0px 0px 2rem",
  },
  btns: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-around",
  },
}));
